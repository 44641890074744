var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCardBody",
    [
      _c(
        "CButtonGroup",
        { attrs: { id: "view-selector" } },
        [
          _c(
            "CButton",
            {
              staticClass: "btn-group-btn",
              attrs: {
                color: !_vm.cardView ? "success" : "dark",
                variant: "outline"
              },
              on: {
                click: function($event) {
                  _vm.cardView = false
                }
              }
            },
            [_vm._v(" List View ")]
          ),
          _c(
            "CButton",
            {
              staticClass: "btn-group-btn",
              attrs: {
                color: _vm.cardView ? "success" : "dark",
                variant: "outline"
              },
              on: {
                click: function($event) {
                  _vm.cardView = true
                }
              }
            },
            [_vm._v(" Card View ")]
          )
        ],
        1
      ),
      !_vm.cardView
        ? _c("CDataTable", {
            attrs: {
              items: _vm.usersData,
              fields: _vm.fields,
              "items-per-page": 10,
              hover: "",
              sorter: "",
              header: !_vm.cardView,
              "sorter-value": _vm.sorterValue,
              pagination: "",
              "table-filter": {
                external: false,
                lazy: false,
                label: "Search",
                placeholder: "search for a report"
              },
              cleaner: "",
              "clickable-rows": ""
            },
            on: { "row-clicked": _vm.handleRowClicked },
            scopedSlots: _vm._u(
              [
                {
                  key: "score",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "td",
                        [
                          _c(
                            "CBadge",
                            { attrs: { color: _vm.getBadge(item.score) } },
                            [_vm._v(" " + _vm._s(item.score) + " ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "timestamp",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("td", [
                        _vm._v(" " + _vm._s(_vm.getDate(item.timestamp)) + " ")
                      ])
                    ]
                  }
                },
                {
                  key: "truck",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("td", [_vm._v(" " + _vm._s(item.name) + " ")])]
                  }
                },
                {
                  key: "tag",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("td", [_vm._v(" " + _vm._s(item.tag) + " ")])]
                  }
                },
                {
                  key: "title",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("td", [_vm._v(" " + _vm._s(item.title) + " ")])]
                  }
                },
                {
                  key: "type",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("td", [_vm._v(" " + _vm._s(item.type) + " ")])]
                  }
                },
                {
                  key: "id",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("td", [_vm._v(" " + _vm._s(item.id) + " ")])]
                  }
                },
                {
                  key: "show_details",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      _c(
                        "td",
                        { staticClass: "py-2" },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: {
                                color: _vm.buttonColor,
                                variant: "outline",
                                square: "",
                                size: "sm"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showReport(item, index)
                                }
                              }
                            },
                            [_vm._v(" Show Report ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "details",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("ReportCollapse", {
                        attrs: { item: item, shown: item.id == _vm.activeId },
                        on: {
                          "show-report-click": function($event) {
                            return _vm.showReport(item)
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              1048694773
            )
          })
        : _c("CDataTable", {
            attrs: {
              items: _vm.usersData,
              fields: _vm.cardViewFields,
              "items-per-page": 20,
              hover: "",
              sorter: "",
              "sorter-value": _vm.sorterValue,
              pagination: "",
              "table-filter": {
                external: false,
                lazy: false,
                label: "Search",
                placeholder: "search for a report"
              },
              cleaner: "",
              "clickable-rows": ""
            },
            scopedSlots: _vm._u([
              {
                key: "dummie",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("ReportCollapse", {
                      attrs: { item: item, shown: "", showReportBtn: "" },
                      on: {
                        "show-report-click": function($event) {
                          return _vm.showReport(item)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }