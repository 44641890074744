var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pb-0 mb-0" },
      [
        _c(
          "CCard",
          {
            staticClass: "rounded data-view-card border-left-warning pb-0 mb-0",
            class: [_vm.customHeight, _vm.customWidth],
            attrs: { "accent-color": _vm.getAccentColor() }
          },
          [
            _c(
              "CCardBody",
              { staticClass: "p-1" },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "\r\n            d-flex\r\n            align-middle\r\n            text-center\r\n            justify-content-center\r\n            card-body\r\n          "
                  },
                  [
                    _c("h1", { attrs: { id: "performance-score" } }, [
                      _vm._v(" " + _vm._s(_vm.score) + " ")
                    ])
                  ]
                ),
                _vm.message
                  ? _c(
                      "CCard",
                      { staticClass: "card col-12 order-2 m-0 p-0" },
                      [
                        _vm._v(" " + _vm._s(_vm.message) + " "),
                        _c("div", { staticClass: "card-body" })
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "bg-dark rounded my-0 py-0" }, [
          _c("p", { staticClass: "text-center data-heading" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }