
<template>
<div>
    <div class="pb-0 mb-0">
      <CCard
        class="rounded data-view-card border-left-warning pb-0 mb-0" :class="[customHeight, customWidth]"
        :accent-color="getAccentColor()"
      >
      <CCardBody class="p-1">
        <span
          class="
            d-flex
            align-middle
            text-center
            justify-content-center
            card-body
          "
        >
          <h1 id="performance-score" class="">
            {{ score }}
          </h1>
        </span>
        <CCard v-if="message" class="card col-12 order-2 m-0 p-0">
      {{ message }}
      <div class="card-body"></div>
      
    </CCard>
      </CCardBody>
      </CCard  >
      <div class="bg-dark rounded my-0 py-0">
        <p class="text-center data-heading">{{title}}</p>
      </div>
    </div>
    
    
    </div>
    
</template>

<script>
export default {
    name: "CScoreCard", 
    props:{
        title: {
            type: String, 
        },
        score: {
            type: String | Number,
        },
        message:{
            type: String, 
        },
        color: {
            type: String, 
        },
        customHeight: {
          type: String,
        },
        customWidth: {
          type: String,
        },
    },
    data(){
        return {

        }
    },
    
    methods:{
        getAccentColor(){
            if(this.color){
                return this.color; 
            }
            else{
                return this.getBadge(this.score); 
            }
        }, 
        getBadge(status) {
      switch (status) {
        case "Good":
          return "success";
        case "Satisfactory":
          return "warning";
        case "Poor":
          return "danger";
        case "Very Poor":
          return "danger";
        default:
          "primary";
      }
    },
    }
}
</script>


<style scoped>
.height70 {
  min-height: 70px;
}

.width170 {
  min-width: 170px;
}
</style>