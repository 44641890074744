var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { lg: "12", color: "danger" } },
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { sm: "12" } },
                    [
                      _c(
                        "CCard",
                        { attrs: { "accent-color": _vm.accentColor } },
                        [
                          _c(
                            "CCardHeader",
                            {
                              staticClass: "standard-card-header",
                              attrs: {
                                color: _vm.backColor,
                                "text-color": "light"
                              }
                            },
                            [
                              _c("CIcon", { attrs: { name: "cil-grid" } }),
                              _vm._v(" All Reports "),
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    [
                                      _c(
                                        "CButtonGroup",
                                        {
                                          staticClass: "align-right float-right"
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "px-1" },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  staticClass: "m-0 p-1",
                                                  attrs: {
                                                    color: "info",
                                                    square: "",
                                                    size: "sm"
                                                  },
                                                  on: { click: _vm.refresh }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {},
                                                    [
                                                      _c("CIcon", {
                                                        staticClass:
                                                          "h-100 m-0 p-0",
                                                        attrs: {
                                                          name: "cil-sync"
                                                        }
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "color:white pull-right"
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("ReportTable", {
                            staticClass: "rule-table",
                            attrs: { usersData: _vm.reportData },
                            on: { "show-report-click": _vm.showReport }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }