<template>
  <CCardBody>
    <CButtonGroup id="view-selector">
      <CButton class="btn-group-btn" :color="!cardView? 'success': 'dark'" variant="outline" @click="cardView = false"> List View </CButton>
      <CButton class="btn-group-btn" :color="cardView? 'success': 'dark'" variant="outline" @click="cardView = true"> Card View </CButton>
    </CButtonGroup>
    <CDataTable v-if="!cardView"
      :items="usersData"
      :fields="fields"
      :items-per-page="10"
      hover
      sorter
      :header="!cardView"
      :sorter-value="sorterValue"
      pagination
      :table-filter='{ external: false, lazy: false, label: "Search", placeholder: "search for a report" }'
      cleaner
      clickable-rows
      
      @row-clicked="handleRowClicked"
    >




    
      <template #score="{ item }">
        <td>
          <CBadge :color="getBadge(item.score)">
            {{ item.score }}
          </CBadge>
        </td>
      </template>
      
      <template #timestamp="{ item }">
        <td>
          {{ getDate(item.timestamp) }}
        </td>
      </template>
      <template #truck="{ item }">
        <td>
          {{ item.name }}
        </td>
      </template>
      <template #tag="{ item }">
        <td>
          {{ item.tag }}
        </td>
      </template>

      <template #title="{ item }">
        <td>
          {{ item.title}}
        </td>
      </template>
      <template #type="{ item }">
        <td>
          {{ item.type }}
        </td>
      </template>

      <template #id="{ item }">
        <td>
          {{ item.id }}
        </td>
      </template>
      <template #show_details="{ item, index }">
        <td  class="py-2">
          <CButton
            :color="buttonColor"
            variant="outline"
            square
            size="sm"
            @click="showReport(item, index)"
          >
          Show Report
          </CButton>
        </td>
      </template>
      <template #details="{ item }">
        <ReportCollapse
          :item="item"
          :shown="item.id == activeId"
          @show-report-click="showReport(item)"
        ></ReportCollapse>
      </template>
    </CDataTable>

    <CDataTable v-else
      :items="usersData"
      :fields="cardViewFields"
      :items-per-page="20"
      hover
      sorter
      :sorter-value="sorterValue"
      pagination
      :table-filter='{ external: false, lazy: false, label: "Search", placeholder: "search for a report" }'
      cleaner
      clickable-rows      
    >
      <template #dummie="{ item }">
        <ReportCollapse
          :item="item"
          shown
          showReportBtn
          @show-report-click="showReport(item)"
        ></ReportCollapse>
      </template>
    </CDataTable>
  </CCardBody>
</template>

<script>


import ReportCollapse from '@/components/reports/ReportCollapse';


export default {
  name: "ReportTable",
  components:{
    ReportCollapse,
  },
  props: {
    buttonColor:{  // Custom Style based on user's theme 
      type: String,
      default: "info"
    },
    usersData:{
      type: Array,
    }, 
    
  },
  data() {
    return {
      sorterValue: {column: null, asc: false}, 
      fields: [
        {
          key: "id",
          name:"id",
          label: "Report ID",
          _style: "width:10%; min-width:10%; max-width:20%",
        },
        { key: "title", _style: "min-width:40%" },
        {key: "truck",_style: "min-width:40%"},
        { key: "score", label: "Tag", _style: "min-width:20%;" },
        { key: "timestamp", label: "Date", _style: "min-width:20%;" },
        {
          key: "type",
          label: "Report Type",
          _style: "min-width:1%",
        },
        {
          key: "show_details",
          label: "Details",
          _style: "min-width:1%",
        },
      ],
      cardViewFields: [
        {key: 'dummie', label: ''},
      ],
      activeId: null,
      cardView: true,
    };
  },
  computed: {
    isAdmin: function () {
      // TODO: Get from User Details
      //this.$auth.
      return false;
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case "Good":
          return "success";
        case "Satisfactory":
          return "warning";
        case "Poor":
          return "danger";
        case "Very Poor":
          return "danger";
        default:
          "primary";
      }
    },
    getDate(myDate, options) {
      if (!options)
        options = {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
      return new Date(parseInt(myDate)).toLocaleDateString("en-US", options);
    },
    handleRowClicked(item){
      if (this.activeId != item.id) {
        this.activeId = item.id;
      } else {
        this.activeId = null;
      }
    },
    showReport(item) {
      /** Raises an Event when the Show Button is Clicked  */
      //alert(item.ID);
      this.$emit("show-report-click", item);
    },
  },
};
</script>