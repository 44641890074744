<template>
  <div>
    <CRow>
      <CCol lg="12" color="danger" class="">
        
        <CRow>
          <CCol sm="12">
            <CCard :accent-color="accentColor">
              <CCardHeader
                :color="backColor"
                text-color="light"
                class="standard-card-header"
              >
                <CIcon name="cil-grid" /> All Reports
                <CRow>
          <CCol>
            <CButtonGroup class="align-right float-right">
              <span class="px-1">
                <CButton
                  color="info"
                  square
                  size="sm"
                  class="m-0 p-1"
                  @click="refresh"
                >
                  <div style="">
                    <CIcon name="cil-sync" class="h-100 m-0 p-0" />
                    <span class="color:white pull-right"></span>
                  </div>
                </CButton>
              </span>
            </CButtonGroup>
          </CCol>
        </CRow>
              </CCardHeader>

              <ReportTable
                @show-report-click="showReport"
                class="rule-table"
                :usersData="reportData"
              />
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<style scoped>
.rule-table {
  font-size: 15px;
}
</style>


<script>
import ReportTable from "@/components/tables/ReportTable";
import ThemeMixin from "@/mixins/ThemeMixin"; 

export default {
  name: "Reports",
  components: {
    ReportTable,
  },
  data() {
    return {
      selectedItem: {},
      activeTab: 0,
      //reportData: [],
    };
  },
  mixins:[ThemeMixin],
  computed: {
    reportData(){
     
      return this.$store.getters.reports.map((item, id) => {
            return { ...item}});
    }, 
   
    path: function () {
      return "https://demo1.aarcomm.io/demo/" + this.selectedItem.path;
    },
    selected: function () {
      return this.selectedItem ? this.selectedItem.title : "";
    },
    ready() {
      if (this.selected) return true;
      else return true;
    },
  },

  async mounted() {
    try {
      this.loadReports();
    } catch (err) {
      console.log(err);
    } finally {
      console.log(this.reportData);
    }
  },
  methods: {


    async refresh(){
      try{
        console.log("Refreshing"); 
        await this.loadReports(); 
      }catch(err){
        console.log(err); 
      }
    },
    async loadReports() {

      try{
        let res = await this.$api.getReports();
        let params = {
          end: Date.now(), 
          
        }
        res = await this.$app.loadReports(params); 
        if(res){
          
          /*
          this.reportData = res.map((item, id) => {
            return { ...item};
          });
          */
        }
      }
      catch(err){
        console.log(err); 
      }
      
    },
    /** Helper function to show Report in the Default Display Tab  */
    showInTab(item) {
      // TODO: This will be deprectated

      this.activeTab = 1;
    },
    /** Helper Display Report Viewer as a new "Page" within the app
     * Preferred Method, as it allows user to use navigation buttons
     */
    showInRouter(item) {
      //this.$router.push({path:"/"+ item.path,params:{id:item.id},query:{id:item.id}})
      //this.$router.push({name:"Report View",params:{id:item.id},query:{id:item.id}});

      let params = {
        id: item.id || item.ID,
        //title: item.title,
        //path: item.path,
      };

      //this.$router.push({name:"Report View",query:{id:item.ID}});
      this.$router.push({ path: "/reports/view", query: params });
    },
    showReport(item) {
      //alert("You Clicked Report ID: " + item.path);
      //this.selected = item.title;
      this.selectedItem = item;
      this.showInRouter(item);
      //this.showInTab(item);

      /** Get The Report by ID and Display in new View / Tab */
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
  },
};
</script>
