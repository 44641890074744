<template>
  <CCollapse :show="shown">
    <CCard class="my-1 pt-1 mx-1">
      <CCardBody class="mt-0 pt-1" @dblclick="showReport">
        <CMedia
          :aside-image-props="{
            src: '/Mapbox_dark_sample.jpg',
            height: 100,
            width: 175
          }"
        >
          <CRow>
            <CCol>
              <h4>
                {{ item.title }}
              </h4>
              <div>
                <span class="text-muted">
                  Date Performed: {{ getDate(item.timestamp) }}
                </span>
                <br />
                <span class="text-muted">
                  Time In: {{ getTime(item.timestamp) }}
                </span>
              </div>
               <CButton v-if="showReportBtn"
                size=""
                color="info"
                class=""
                @click="showReport(item, index)"
              >
              Show Report
              </CButton>
              <CButton
                v-if="false"
                size="sm"
                color="danger"
                class="ml-1"
                @click="showWarning"
              >
                Delete
              </CButton>
            </CCol>
            <CCol col="4" class="h-50 my-2">
              <CScoreCard
                class="h-50 my-2"
                :score="item.score"
                title="Safety Score"
              />
            </CCol>
          </CRow>
        </CMedia>

        <CModal
          title="Warning"
          color="danger"
          :centered="true"
          :show.sync="warningModal"
        >
          {{ warningMsg }}
          <template #footer>
            <CButton @click="warningModal = false" color="danger">No</CButton>
            <CButton @click="onDeleteClick(item)" color="success"
              >Yes</CButton
            >
          </template>
        </CModal>
      </CCardBody>
    </CCard>
  </CCollapse>
</template>

<script>
import CScoreCard from "@/components/reports/CScoreCard";

export default {
  name: "ReportCollapse",
  components: {
    CScoreCard
  },
  props: {
    shown: {
      type: Boolean,
      default: true,
    },
    item: Object,
    showReportBtn: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      warningModal: false,
      warningMsg: "",
    };
  },
  computed: {
    isAdmin: function() {
      // TODO: Get from User Details
      //this.$auth.
      return false;
    },
  },
  methods: {
    onDeleteClick() { // h_todo
      // TODO: Delete Report from List
      //alert(item.ID);
      this.warningModal = false;
    },
    getTime(myDate, options) {
      // TODO: Choose Data / Time Format based on region
      return this.$moment(parseInt(myDate)).format("HH:mm:ss A");

      // return new Date(parseInt(myDate)).toTimeString("en-US", options);
    },
    getDate(myDate, options) {
      if (!options)
        options = {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric"
        };
      return new Date(parseInt(myDate)).toLocaleDateString("en-US", options);
    },

    showReport() {
      /** Raises an Event when the Show Button is Clicked  */
      //alert(item.ID);
      this.$emit("show-report-click");
    },

    showWarning() {
      this.warningModal = true;
      this.warningMsg =
        "Are you Really Sure you want to Delete Report " + this.item.id;
    }
  }
};
</script>
