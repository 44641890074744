var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCollapse",
    { attrs: { show: _vm.shown } },
    [
      _c(
        "CCard",
        { staticClass: "my-1 pt-1 mx-1" },
        [
          _c(
            "CCardBody",
            { staticClass: "mt-0 pt-1", on: { dblclick: _vm.showReport } },
            [
              _c(
                "CMedia",
                {
                  attrs: {
                    "aside-image-props": {
                      src: "/Mapbox_dark_sample.jpg",
                      height: 100,
                      width: 175
                    }
                  }
                },
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _c("h4", [
                            _vm._v(" " + _vm._s(_vm.item.title) + " ")
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                " Date Performed: " +
                                  _vm._s(_vm.getDate(_vm.item.timestamp)) +
                                  " "
                              )
                            ]),
                            _c("br"),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                " Time In: " +
                                  _vm._s(_vm.getTime(_vm.item.timestamp)) +
                                  " "
                              )
                            ])
                          ]),
                          _vm.showReportBtn
                            ? _c(
                                "CButton",
                                {
                                  attrs: { size: "", color: "info" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showReport(_vm.item, _vm.index)
                                    }
                                  }
                                },
                                [_vm._v(" Show Report ")]
                              )
                            : _vm._e(),
                          false
                            ? _c(
                                "CButton",
                                {
                                  staticClass: "ml-1",
                                  attrs: { size: "sm", color: "danger" },
                                  on: { click: _vm.showWarning }
                                },
                                [_vm._v(" Delete ")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { staticClass: "h-50 my-2", attrs: { col: "4" } },
                        [
                          _c("CScoreCard", {
                            staticClass: "h-50 my-2",
                            attrs: {
                              score: _vm.item.score,
                              title: "Safety Score"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CModal",
                {
                  attrs: {
                    title: "Warning",
                    color: "danger",
                    centered: true,
                    show: _vm.warningModal
                  },
                  on: {
                    "update:show": function($event) {
                      _vm.warningModal = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer",
                      fn: function() {
                        return [
                          _c(
                            "CButton",
                            {
                              attrs: { color: "danger" },
                              on: {
                                click: function($event) {
                                  _vm.warningModal = false
                                }
                              }
                            },
                            [_vm._v("No")]
                          ),
                          _c(
                            "CButton",
                            {
                              attrs: { color: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.onDeleteClick(_vm.item)
                                }
                              }
                            },
                            [_vm._v("Yes")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [_vm._v(" " + _vm._s(_vm.warningMsg) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }